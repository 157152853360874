import React from 'react';

import { BrowserRouter, Route, Routes  } from 'react-router-dom'

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Home from './views/Home'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header /> 

          <div className="MainView">
            <Routes >
              <Route path='/' element={<Home/>} />
              
                
              <Route path='*'  element={<Home/>}  status={404}/>
            </Routes >
          </div>
          
          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
