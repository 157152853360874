import React, { useState, useEffect } from 'react';

const Header = ({user}) => {    
    
   

    return (
        < >

        </>
    );
}

export default (Header)