import React from 'react'


const Footer = (props) => {


    return (
        <footer>
          <div className={"copyright"}>
              Copyright © 2024 <a href="https://idrate.co/">idrate.co</a>. All rights reserved.
          </div>
        </footer>
    )
}

export default (Footer)