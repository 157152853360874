
import React, { useEffect,useState } from 'react';
import axios from 'axios'
import { Divider,Container ,CardContent,CardActions,
  Box,
  Alert,
  CardHeader,Grid,
  TextField, 
Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export default function Home() {

  const [values, setValues] = useState({service:"SelectedService"});
  const [Status, setStatus] = useState({loading:false, thankyou:false, error:null,timeoutTimer:null});
  
  


  return (<>

  

<Box className="Home-headerBox">
      <Container>
        <Box>

          <Grid container 
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <img className='Home-headerBox-logo' src="/images/logo/logo.png"/>

              <h1>Idrate</h1>
              <p>Helps you drink more water while you work</p>
              <h2>Coming soon</h2>
              <Button className="subscribeButton" href="https://subscribe.idrate.co/" target='_blank'>Join the Wait List</Button>

<Box sx={{mt:4}} display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
<img className="deviceImage" src='/images/device/device.png'/>


</Box>

              
            </Grid>
            <Grid   item  xs={12} sm={12} md={6} lg={6} xl={6} display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
              
              <img className="deviceImage" src='/images/device/device.png'/>

            </Grid>


          </Grid>

        </Box>
  </Container>
</Box>










<Box className="Home-features">
  <Container>

        <Grid container  sx={{ pt: 4,pb: 4 }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>


        <Box className="PointBox">
          <h2>8 Cups a <strong>day</strong></h2>
          <p>An average person requires a minimum of 8 cups of water a day. </p>
        </Box>

        <Divider sx={{borderColor:"#fff"}} />

        <Box className="PointBox">
          <h2>Other food and drinks <strong>dehydrate</strong></h2>
          <p>Coffee, soft drinks, alcohol  and many other food/drinks can dehydrate. </p>
        </Box>

        <Divider sx={{ borderColor:"#fff",display: { xs: 'block', sm: 'block' , md: 'none' } }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

        <Box className="PointBox">
          <h2>Re-hydrate with <strong>Idrate</strong></h2>
          <p>Helps you drink more water while you work.</p>

        </Box>
        <Divider  sx={{borderColor:"#fff"}}/>

        <Box className="PointBox">


          <h2>Track your water <strong>Consumption</strong></h2>
          <p>track how much you are drinking with our app.</p>

          </Box>


          </Grid>
          </Grid>





  </Container>
</Box>










<Box className="Home-headerBox">
  <Container>


          <Grid  sx={{ mt: 10,mb: 10 }} container spacing={2}>
            <Grid className='Home-app-view' item xs={12} sm={12} md={4} lg={4} xl={4}>
              <img className='Home-app-view-image' src="/images/app/blank_coming_soon_blue.png"/>
              <Box  className='Home-app-view-text'>
                Home 
              </Box>
            </Grid>
            <Grid className='Home-app-view' item xs={12} sm={12} md={4} lg={4} xl={4}>
              <img className='Home-app-view-image' src="/images/app/blank_coming_soon_blue.png"/>
              <Box  className='Home-app-view-text'>
                Stats
              </Box>
            </Grid>
            <Grid className='Home-app-view' item xs={12} sm={12} md={4} lg={4} xl={4}>
              <img className='Home-app-view-image' src="/images/app/blank_coming_soon_blue.png"/>
              <Box  className='Home-app-view-text'>
                Setting
              </Box>
            </Grid>
          </Grid>



          <Box sx={{mb:10}}>
            <h2>Schedule how much to <strong>drink</strong></h2>
            <p>change the times your required to be drinking.</p>

            
      <Box sx={{textAlign:"center"}}>
        <Button className="subscribeButton" href="https://subscribe.idrate.co/" target='_blank'>Join the Wait List</Button>
      </Box>
          </Box>


        
  </Container>
</Box>








<Box className="Home-howItWorks">
  <Container sx={{pt:2,pb:10}}>
    <Box sx={{mt:2}}>
    
    <h2 >How It Works</h2>

      <Grid container >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className="StepBox">
            <h3>Power Device</h3>
            <p>Connect the USB-C to a power source. The device needs a 5V USB, 1A supply, or at least 5W.</p>
          </Box>
        </Grid>

        
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{"textAlign":"center"}}>
          <img className="Stepimage" src='/images/steps/power-device.png'/>
        </Grid>



        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{"textAlign":"center"}} display={{ xs: 'none', sm: 'none', md: 'block', }}>
          <img className="Stepimage" src='/images/steps/placing-water.png' />
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
          <Box className="StepBox">
            <h3>Placing Water</h3>
            <p>Position a cup of water on the device; it will change to green, indicating that the cup has been recognized.</p>
          </Box>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{"textAlign":"center"}} display={{ md: 'none', lg: 'none', xl: 'none' }}>
        <img className="Stepimage" src='/images/steps/placing-water.png'/>
        </Grid>


        

        
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box className="StepBox">
            <h3>Drinking</h3>
            <p>Whenever the device begins flashing red, or at any given moment, remember to drink water. It will turn green as a signal that the cup has been successfully detected.</p>
          </Box>
        </Grid>

        
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}   sx={{"textAlign":"center"}}>
        <img className="Stepimage" src='/images/steps/drinking.png'/>
        </Grid>



        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  sx={{"textAlign":"center"}} display={{ xs: 'none', sm: 'none' , md: 'block' }}>
        <img className="Stepimage" src='/images/steps/connecting.png'/>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
          <Box className="StepBox">
            <h3>Connecting</h3>
            <p>Connect using Bluetooth to track water Consumption and change settings of the device.</p>
          </Box>
        </Grid>

        
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  sx={{"textAlign":"center"}} display={{ md: 'none', lg: 'none', xl: 'none' }}>
        <img className="Stepimage" src='/images/steps/connecting.png'/>
        </Grid>

        





        




        </Grid>


      </Box>


  </Container>
</Box>








      <Box  sx={{mt:10,mb:10}} className="Home-Features">
        <Container>
          <h2>Features</h2>

          <Grid container spacing={2} sx={{mt:2}}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            

            <TableContainer >

      <Table  aria-label="simple table">
        <TableBody>
            
            
            <TableRow key="Dimensions">
              <TableCell component="th" scope="row">Dimensions</TableCell>
              <TableCell align="right">83mm x 83mm x 10mm (not including USB)</TableCell>
            </TableRow>

            <TableRow key="Cable">
              <TableCell component="th" scope="row">Cable</TableCell>
              <TableCell align="right">USB C, 500mm cable</TableCell>
            </TableRow>
            
            <TableRow key="Weight">
              <TableCell component="th" scope="row">Weight</TableCell>
              <TableCell align="right">75g</TableCell>
            </TableRow>
            
            <TableRow key="Power">
              <TableCell component="th" scope="row">Power</TableCell>
              <TableCell align="right">5V 1A (5W min)</TableCell>
            </TableRow>
            
            <TableRow key="Connectivity">
              <TableCell component="th" scope="row">Connectivity</TableCell>
              <TableCell align="right">Bluetooth</TableCell>
            </TableRow>
            


            
        </TableBody>
      </Table>
    </TableContainer>
            
            </Grid>
            <Grid sx={{textAlign:"center"}} item  xs={12} sm={12} md={6} lg={6} xl={6}>
              
            <img className="deviceImage" src='/images/device/top.png'/>
            <img className="deviceImage" src='/images/device/front.png'/>

            </Grid>
          </Grid>


        </Container>
      </Box>


      <Box sx={{textAlign:"center"}}>
        <Button className="subscribeButton" href="https://subscribe.idrate.co/" target='_blank'>Join the Wait List</Button>
      </Box>


      <Box sx={{mt:10,mb:10}} className="Home-Progress">
        <Container>
          <h2>Progress</h2>

          <Box className='progressBar'>
          <Stepper  activeStep={4} orientation="vertical">
              <Step key="">
                <StepLabel>
                  Idea
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>


              
              <Step key="">
                <StepLabel>
                  Design
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>


              
              <Step key="">
                <StepLabel>
                  Prototype
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>
              
              <Step key="">
                <StepLabel>
                  Testing
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>
              
              <Step key="">
                <StepLabel>
                  Tooling
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>
              


              <Step key="">
                <StepLabel>
                  Manufacturing
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>

              
              <Step key="">
                <StepLabel>
                  Selling
                </StepLabel>
                <StepContent>
                  
                </StepContent>
              </Step>
              

          </Stepper>

          </Box>
        </Container>
      </Box>

    </>
  )
}
